import React, { useEffect } from 'react';
import $ from 'jquery';
import blucheck from '@img/blucheck.webp';
import { useNavigate } from "react-router-dom";


import '@styles/ModalDetailStore.scss';

const ModalResponse = ({message, url}) => {
    useEffect(() => {
        $('#response-modal').modal({ backdrop: 'static', keyboard: false });
    }, []);

    const navigate = useNavigate();
    const handleClose = () => {
        $('#response-modal').modal('hide');
        navigate(url)

    };

    return (
        message ?
            <div className="Response-modal">
                <div className="modal fade" id="response-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" onClick={handleClose}  aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="Response-content">
                                    <div className='header-text'>
                                        <img
                                            src={blucheck}
                                            alt="firulife-user"
                                        />
                                        <p className='title-text'>Se guardó con éxito</p>
                                    </div>
                                    <div className='context-text'>
                                        {message}
                                    </div>
                                    <div className='button' onClick={handleClose}>
                                        Entendido
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
        <div className="Response-modal">
                <div className="modal fade" id="response-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                            <p className='MessageError'>¡Lo sentimos!, No hay data disponible en este momento</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ModalResponse;